import { IScrollable } from '~/types/components'

export const useScroll = () => {
  const { $dataLayer } = useNuxtApp()
  const { params } = useRoute()
  const { push, currentRoute } = useRouter()

  const scrollTo = (item: IScrollable, category = '') => {
    push({
      params,
      query: currentRoute.value.query,
      hash: `#${item.key}`
    }).catch(() => {})

    $dataLayer.linkClick({
      category: item.analyticCategory || category,
      action: item.analyticName
    })
  }

  return {
    scrollTo
  }
}
